<template>
  <div class="content-wrapper">
    <b-card footer-class="text-right bg-white" border-variant="danger" header-text-variant="danger">
      <b-card-title class="font-weight-light mb-4" :title="$t('dataset.delete-dataset')" />

      <p>{{ $t('dataset.delete-warning') }}</p>

      <template #footer>
        <b-button size="sm" variant="outline-danger" @click="openDeleteModal(datasetId)"
          >{{ $t('dataset.delete-dataset') }}
        </b-button>
      </template>
    </b-card>

    <DeleteModal
      v-model="datasetDeleteModal.show"
      :title="$t('dataset.delete-dataset')"
      :delete-message="$t('dataset.delete-modal-warning')"
      :delete-function="deleteDatasetAndRedirect"
    />
  </div>
</template>

<script>
import { ApiMixin } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import DeleteModal from '@/components/modals/DeleteModal'
import { DatasetDeletionMixin } from '@/mixins/DatasetDeletionMixin'

export default {
  components: { DeleteModal },
  mixins: [ApiMixin, NotificationMixin, DatasetDeletionMixin],
  name: 'DeleteForm',
  props: {
    datasetId: {
      required: true,
      type: Number
    },
    redirectFunc: {
      required: false,
      type: Function
    }
  },
  methods: {
    deleteDatasetAndRedirect() {
      this.deleteDataset().then(() => {
        if (this.redirectFunc) {
          this.redirectFunc()
        } else {
          this.$router.push({ name: 'datasets' })
        }
      })
    }
  }
}
</script>
