<template>
  <div class="pageable-container">
    <b-row class="mb-3 classification-row">
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-for="(classification, index) in classificationData.slice(firstPage, lastPage)"
        class="classification-col"
        :key="classification.id ? `cid-${classification.id}` : `idx-${index}`"
      >
        <ClassificationMask
          :disableAll="disableAll"
          v-model="classificationData[firstPage + index]"
          :insect-order="insectOrders"
          :insect-family="insectFamilies"
          :insect-genus="insectGenuses"
          :insect-species="insectSpecies"
        />

        <b-button
          v-if="!disableAll"
          size="xs"
          :variant="classificationDisabled(classification) ? 'outline-secondary' : 'outline-danger'"
          class="float-right"
          @click="openDeleteModal(classification.id, index)"
          :disabled="classificationDisabled(classification)"
        >
          <b-icon icon="x" scale="1.5" />
          {{ $t('general.delete') }}
        </b-button>
      </b-col>

      <b-col
        class="col-4 pl-5 pr-5 justify-content-center add-classification-col"
        v-if="classificationData.slice(firstPage, lastPage).length !== pageSize && !disableAll"
      >
        <b-button
          class="h-25 align-self-center add-button"
          pill
          variant="outline-secondary"
          @click="addClassification()"
        >
          {{ $t('dataset.add-classification') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        pills
        :total-rows="cols"
        :per-page="pageSize"
        :current-page="currentPage"
        size="sm"
      />
    </b-row>

    <DeleteModal
      v-model="showDeleteModal"
      :title="$t('dataset.delete-classification')"
      :delete-message="$t('dataset.delete-classification-modal-warning')"
      :delete-function="removeClassification"
    />
  </div>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import ClassificationMask from '@/components/datasets/classifications/ClassificationMask'
import DeleteModal from '@/components/modals/DeleteModal'

export default {
  name: 'ClassificationPageable',
  components: { DeleteModal, ClassificationMask },
  mixins: [ApiMixin, NotificationMixin],
  props: {
    value: {
      required: true,
      type: Array
    },
    datasetId: {
      required: true,
      type: Number
    },
    disableAll: {
      required: false,
      type: Boolean,
      default: false
    },
    pageSize: {
      required: true,
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      currentPage: 1,
      showDeleteModal: false,
      classificationToDelete: {
        id: null,
        index: null
      },
      insectOrders: [],
      insectFamilies: [],
      insectGenuses: [],
      insectSpecies: []
    }
  },
  computed: {
    classificationData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    cols() {
      return this.classificationData.length + 1
    },
    firstPage() {
      return (this.currentPage - 1) * this.pageSize
    },
    lastPage() {
      return this.firstPage + this.pageSize
    }
  },
  mounted() {
    this.getAllInsectOrders()
    this.getAllInsectFamilies()
    this.getAllInsectGenuses()
    this.getAllInsectSpecies()
  },
  methods: {
    getAllInsectOrders() {
      let self = this
      self.getRequest(
        `/insect_orders`,
        new RequestConfig()
          .onSuccess(res => {
            self.insectOrders = res.data
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    getAllInsectFamilies() {
      let self = this
      self.getRequest(
        `/insect_families`,
        new RequestConfig()
          .onSuccess(res => {
            self.insectFamilies = res.data
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    getAllInsectGenuses() {
      let self = this
      self.getRequest(
        `/insect_genuses`,
        new RequestConfig()
          .onSuccess(res => {
            self.insectGenuses = res.data
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    getAllInsectSpecies() {
      let self = this
      self.getRequest(
        `/insect_species`,
        new RequestConfig()
          .onSuccess(res => {
            self.insectSpecies = res.data
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    addClassification() {
      this.classificationData.push({
        id: null,
        insect_order: { gbif_id: null, id: null, name: '' },
        insect_family: { gbif_id: null, id: null, name: '', order: { id: null } },
        insect_genus: { gbif_id: null, id: null, name: '', family: { id: null } },
        insect_species: { gbif_id: null, id: null, name: '', genus: { id: null } },
        type: 'HUMAN',
        probability: 0
      })
    },
    openDeleteModal(id, index) {
      this.classificationToDelete.id = id
      this.classificationToDelete.index = index
      this.showDeleteModal = true
    },
    removeClassification() {
      let index = this.firstPage + this.classificationToDelete.index
      if (this.classificationToDelete.id === null) {
        this.classificationData.splice(index, 1)
        this.displaySuccess(this.$t('dataset.classification-deletion-successful'))
      } else {
        this.deleteClassification(index)
      }
      this.classificationToDelete = {
        id: null,
        index: null
      }
    },
    deleteClassification(index) {
      let self = this
      let datasetId = self.datasetId
      let classificationId = self.classificationToDelete.id
      self.deleteRequest(
        `/datasets/${datasetId}/classifications/${classificationId}`,
        new RequestConfig()
          .onSuccess(() => {
            self.classificationData.splice(index, 1)
            self.displaySuccess(this.$t('dataset.classification-deletion-successful'))
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    classificationDisabled(classification) {
      return classification.type === 'GROUNDTRUTH'
    }
  }
}
</script>

<style lang="scss" scoped>
.classification-row {
  min-height: 380px;
}

.classification-col {
  &:not(:last-child) {
    border-right: 1px solid lightgray;
    padding-right: 1.5rem;
  }

  &:not(:first-child) {
    padding-left: 1.5rem;
  }
}

.add-classification-col {
  display: flex;

  .add-button {
    color: #6c757d;
    border-style: dashed;

    &:hover {
      border-style: solid;
      background-color: var(--light);
    }
  }
}
</style>
