<template>
  <b-card>
    <b-card-title class="font-weight-light mb-4" :title="$t('dataset.media')" />

    <b-row class="justify-content-start flex-row media-row px-3">
      <div v-for="(item, index) in datasetImages" :key="index" class="mr-3 mr-md-2 text-center">
        <video v-if="item.type === 'video'" controls height="150px" :autoplay="item.autoplay" class="media-item">
          <source v-for="(video, index) in item.sources" :key="index" :src="video.src" :type="video.type" />
        </video>
        <img v-else v-lazy="item.src || item.thumb" class="media-item" @click="openGallery(index)" alt="" />
      </div>
      <LightBox :show-light-box="false" ref="lightbox" v-if="datasetImages.length > 0" :media="datasetImages" />
    </b-row>
  </b-card>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import LightBox from 'vue-image-lightbox'
// Use only when you are using Webpack:
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: 'MediaForm',
  components: { LightBox },
  mixins: [ApiMixin, NotificationMixin],
  props: {
    datasetId: {
      required: true,
      type: Number
    },
    hasVideo: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      apiBaseUrl: this.$store.getters.getConfig.API_BASE_URL,
      datasetImagesData: null,
      datasetImages: []
    }
  },
  watch: {
    datasetId: {
      handler() {
        this.getDatasetImages()
        if (this.hasVideo) {
          this.setVideoUrl()
        }
      }
    }
  },
  mounted() {
    this.getDatasetImages()
    if (this.hasVideo) {
      this.setVideoUrl()
    }
  },
  methods: {
    getDatasetImages() {
      let self = this
      let datasetId = self.datasetId
      self.getRequest(
        `/datasets/${datasetId}/dataset_images`,
        new RequestConfig()
          .onSuccess(res => {
            self.datasetImagesData = res.data
            self.setImageUrls()
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    setVideoUrl() {
      this.datasetImages.push({
        thumb: this.apiBaseUrl + '/datasets/' + this.datasetId + '/video_s3_object/download',
        sources: [
          {
            src: this.apiBaseUrl + '/datasets/' + this.datasetId + '/video_s3_object/download',
            type: 'video/mp4'
          }
        ],
        type: 'video',
        width: 800,
        height: 600,
        autoplay: false
      })
    },
    setImageUrls() {
      this.datasetImagesData.forEach(image => {
        this.datasetImages.push({
          thumb: `${this.apiBaseUrl}/datasets/${this.datasetId}/dataset_images/${image.id}/download`,
          src: `${this.apiBaseUrl}/datasets/${this.datasetId}/dataset_images/${image.id}/download`
        })
      })
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

<style lang="scss">
.vue-lb-footer {
  justify-content: center;

  .vue-lb-footer-info {
    display: none;
  }
}

div.media-row > *:last-child {
  margin-right: 0 !important;
}

.media-item {
  border: 1px solid dimgray;
}

img.media-item {
  height: 150px;
  cursor: pointer;
}
</style>
