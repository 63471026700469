<template>
  <div class="classification-mask-container">
    <b-form-group
      :label="$t('dataset.insect-order')"
      label-for="insect-order"
      label-cols-sm="4"
      label-align-sm="left"
      :disabled="disableAll"
    >
      <vue-typeahead-bootstrap
        id="insect-order"
        ref="orderTypeahead"
        :placeholder="$t('dataset.search-insect-order')"
        :data="orderSuggestions"
        :show-on-focus="true"
        :disabled="isDisabled || classification.insect_family.id !== null || disableAll"
        highlightClass="highlight-class"
        v-model="orderQuery"
        :serializer="order => order.name"
        @keyup.enter="catchKeyupEvent"
        @hit="hitEventWrapper('order', $event)"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <div v-if="data.id === classification.insect_order.id">
            <span v-html="htmlText" class="selected-item">{{ data.name }}</span>
            <span class="selected-item-after" style="color: black">x</span>
          </div>
          <span v-else v-html="htmlText"></span>
        </template>
      </vue-typeahead-bootstrap>
    </b-form-group>
    <b-form-group
      :label="$t('dataset.insect-family')"
      label-for="insect-family"
      label-cols-sm="4"
      label-align-sm="left"
      :disabled="disableAll"
    >
      <vue-typeahead-bootstrap
        id="insect-family"
        ref="familyTypeahead"
        :placeholder="$t('dataset.search-insect-family')"
        :data="familySuggestions"
        :show-on-focus="true"
        :disabled="isDisabled || classification.insect_genus.id !== null || disableAll"
        v-model="familyQuery"
        :serializer="family => family.name"
        @keyup.enter="catchKeyupEvent"
        @hit="hitEventWrapper('family', $event)"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <div v-if="data.id === classification.insect_family.id">
            <span v-html="htmlText" class="selected-item">{{ data.name }}</span>
            <span class="selected-item-after" style="color: black">x</span>
          </div>
          <span v-else v-html="htmlText"></span>
        </template>
      </vue-typeahead-bootstrap>
    </b-form-group>
    <b-form-group :label="$t('dataset.insect-genus')" label-for="insect-genus" label-cols-sm="4" label-align-sm="left">
      <vue-typeahead-bootstrap
        id="insect-genus"
        ref="genusTypeahead"
        :placeholder="$t('dataset.search-insect-genus')"
        :data="genusSuggestions"
        :show-on-focus="true"
        :disabled="isDisabled || classification.insect_species.id !== null || disableAll"
        v-model="genusQuery"
        :serializer="genus => genus.name"
        @keyup.enter="catchKeyupEvent"
        @hit="hitEventWrapper('genus', $event)"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <div v-if="data.id === classification.insect_genus.id">
            <span v-html="htmlText" class="selected-item">{{ data.name }}</span>
            <span class="selected-item-after" style="color: black">x</span>
          </div>
          <span v-else v-html="htmlText"></span>
        </template>
      </vue-typeahead-bootstrap>
    </b-form-group>
    <b-form-group
      :label="$t('dataset.insect-species')"
      label-for="insect-species"
      label-cols-sm="4"
      label-align-sm="left"
    >
      <vue-typeahead-bootstrap
        id="insect-species"
        ref="speciesTypeahead"
        :placeholder="$t('dataset.search-insect-species')"
        :data="speciesSuggestions"
        :show-on-focus="true"
        :disabled="isDisabled || disableAll"
        v-model="speciesQuery"
        :serializer="species => species.name"
        @keyup.enter="catchKeyupEvent"
        @hit="hitEventWrapper('species', $event)"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <div v-if="data.id === classification.insect_species.id">
            <span v-html="htmlText" class="selected-item">{{ data.name }}</span>
            <span class="selected-item-after" style="color: black">x</span>
          </div>
          <span v-else v-html="htmlText"></span>
        </template>
      </vue-typeahead-bootstrap>
    </b-form-group>

    <b-form-group
      :label="$t('dataset.capture')"
      label-for="capture"
      label-cols-sm="4"
      label-align-sm="left"
      class="mt-5"
    >
      <b-form-input id="capture" v-model="classification.type" disabled />
    </b-form-group>

    <b-form-group :label="$t('dataset.probability')" label-for="probability" label-cols-sm="4" label-align-sm="left">
      <b-form-input
        id="probability"
        type="number"
        step="0.00001"
        min="0"
        max="1"
        @blur="maximizeProbability"
        class="w-50"
        v-model="classification.probability"
        :disabled="isDisabled || disableAll"
      />
    </b-form-group>
  </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  name: 'ClassificationMask',
  components: { VueTypeaheadBootstrap },
  props: {
    value: {
      required: true,
      type: Object
    },
    insectOrder: {
      required: true,
      type: Array
    },
    insectFamily: {
      required: true,
      type: Array
    },
    insectGenus: {
      required: true,
      type: Array
    },
    insectSpecies: {
      required: true,
      type: Array
    },
    disableAll: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderQuery: '',
      familyQuery: '',
      genusQuery: '',
      speciesQuery: '',
      keyUp: false
    }
  },
  mounted() {
    this.initializeQueries()
  },
  computed: {
    classification: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isDisabled() {
      return this.classification.type === 'GROUNDTRUTH'
    },
    orderSuggestions() {
      let suggestions = []
      if (this.orderQuery === '' && !this.keyUp) {
        if (this.$refs.orderTypeahead) {
          if (!this.$refs.orderTypeahead.isFocused) {
            suggestions.push({ id: null, name: '' })
          }
        }
      }
      this.insectOrder.forEach(order => {
        suggestions.push({
          id: order.id,
          name: order.name
        })
      })
      return suggestions
    },
    familySuggestions() {
      let suggestions = []
      if (this.familyQuery === '' && !this.keyUp) {
        if (this.$refs.familyTypeahead) {
          if (!this.$refs.familyTypeahead.isFocused) {
            suggestions.push({ id: null, name: '' })
          }
        }
      }
      if (this.classification.insect_order.id === null) {
        this.insectFamily.forEach(family => {
          suggestions.push({
            id: family.id,
            name: family.name
          })
        })
      } else {
        this.insectFamily.forEach(family => {
          if (this.classification.insect_order.id === family.order.id) {
            suggestions.push({
              id: family.id,
              name: family.name
            })
          }
        })
      }
      return suggestions
    },
    genusSuggestions() {
      let suggestions = []
      if (this.genusQuery === '' && !this.keyUp) {
        if (this.$refs.genusTypeahead) {
          if (!this.$refs.genusTypeahead.isFocused) {
            suggestions.push({ id: null, name: '' })
          }
        }
      }
      if (this.classification.insect_family.id === null) {
        this.insectGenus.forEach(genus => {
          this.familySuggestions.forEach(family => {
            if (genus.family.id === family.id) {
              suggestions.push({
                id: genus.id,
                name: genus.name
              })
            }
          })
        })
      } else {
        this.insectGenus.forEach(genus => {
          if (this.classification.insect_family.id === genus.family.id) {
            suggestions.push({
              id: genus.id,
              name: genus.name
            })
          }
        })
      }
      return suggestions
    },
    speciesSuggestions() {
      let suggestions = []
      if (this.speciesQuery === '' && !this.keyUp) {
        if (this.$refs.speciesTypeahead) {
          if (!this.$refs.speciesTypeahead.isFocused) {
            suggestions.push({ id: null, name: '' })
          }
        }
      }
      if (this.classification.insect_genus.id === null) {
        this.insectSpecies.forEach(species => {
          this.genusSuggestions.forEach(genus => {
            if (species.genus.id === genus.id) {
              suggestions.push({
                id: species.id,
                name: species.name
              })
            }
          })
        })
      } else {
        this.insectSpecies.forEach(species => {
          if (this.classification.insect_genus.id === species.genus.id) {
            suggestions.push({
              id: species.id,
              name: species.name
            })
          }
        })
      }
      return suggestions
    }
  },
  methods: {
    initializeQueries() {
      this.orderQuery = this.value.insect_order.name
      this.familyQuery = this.value.insect_family.name
      this.genusQuery = this.value.insect_genus.name
      this.speciesQuery = this.value.insect_species.name
    },
    catchKeyupEvent() {
      this.keyUp = !this.keyUp
    },
    hitEventWrapper(type, event) {
      // function is triggered twice at keydown.enter and keyup.enter --> prevent multiple function calls
      if (!this.keyUp || this.isQueryUnequalObjectName()) {
        switch (type) {
          case 'order':
            if (this.isOrderSelected(event)) {
              this.emptyInsectFields('order')
            } else {
              this.classification.insect_order.name = event.name
              this.classification.insect_order.id = event.id
            }
            this.updateInsectOrder()
            break
          case 'family':
            if (this.isFamilySelected(event)) {
              this.emptyInsectFields('family')
            } else {
              this.classification.insect_family.name = event.name
              this.classification.insect_family.id = event.id
            }
            this.setInsectOrder()
            break
          case 'genus':
            if (this.isGenusSelected(event)) {
              this.emptyInsectFields('genus')
            } else {
              this.classification.insect_genus.name = event.name
              this.classification.insect_genus.id = event.id
            }
            this.setInsectFamily()
            break
          case 'species':
            if (this.isSpeciesSelected(event)) {
              this.emptyInsectFields('species')
            } else {
              this.classification.insect_species.name = event.name
              this.classification.insect_species.id = event.id
            }
            this.setInsectGenus()
            break
        }
      }
      this.keyUp = false
    },
    emptyInsectFields(type) {
      switch (type) {
        case 'order':
          this.orderQuery = ''
          this.classification.insect_order.id = null
          this.classification.insect_order.name = ''
          this.classification.insect_family.gbif_id = null
          this.classification.insect_family.order.id = null
          this.$refs.orderTypeahead.inputValue = ''
          break
        case 'family':
          this.familyQuery = ''
          this.classification.insect_family.id = null
          this.classification.insect_family.name = ''
          this.classification.insect_family.gbif_id = null
          this.classification.insect_genus.family.id = null
          this.$refs.familyTypeahead.inputValue = ''
          break
        case 'genus':
          this.genusQuery = ''
          this.classification.insect_genus.id = null
          this.classification.insect_genus.name = ''
          this.classification.insect_genus.gbif_id = null
          this.classification.insect_species.genus.id = null
          this.$refs.genusTypeahead.inputValue = ''
          break
        case 'species':
          this.speciesQuery = ''
          this.classification.insect_species.id = null
          this.classification.insect_species.name = ''
          this.classification.insect_species.gbif_id = null
          this.$refs.speciesTypeahead.inputValue = ''
          break
      }
    },
    isOrderSelected(event) {
      return this.classification.insect_order.id === event.id
    },
    isFamilySelected(event) {
      return this.classification.insect_family.id === event.id
    },
    isGenusSelected(event) {
      return this.classification.insect_genus.id === event.id
    },
    isSpeciesSelected(event) {
      return this.classification.insect_species.id === event.id
    },
    isQueryUnequalObjectName() {
      return (
        this.speciesQuery !== this.classification.insect_species.name ||
        this.genusQuery !== this.classification.insect_genus.name ||
        this.familyQuery !== this.classification.insect_family.name ||
        this.orderQuery !== this.classification.insect_order.name
      )
    },
    updateInsectOrder() {
      if (this.classification.insect_order.id !== null) {
        this.insectOrder.forEach(order => {
          if (order.id === this.classification.insect_order.id) {
            this.classification.insect_order.gbif_id = order.gbif_id
            this.classification.insect_order.name = order.name
            this.orderQuery = order.name

            this.classification.insect_family.order.id = order.id
          }
        })
      } else {
        this.emptyInsectFields('order')
      }
    },
    setInsectOrder() {
      if (this.classification.insect_family.id !== null) {
        this.insectFamily.forEach(family => {
          if (family.id === this.classification.insect_family.id) {
            this.classification.insect_family.gbif_id = family.gbif_id
            this.classification.insect_family.name = family.name
            this.classification.insect_family.order.id = family.order.id
            this.familyQuery = family.name

            this.classification.insect_order.id = family.order.id

            this.classification.insect_genus.family.id = family.id
          }
        })
      } else {
        this.emptyInsectFields('family')
      }
      this.updateInsectOrder()
    },
    setInsectFamily() {
      if (this.classification.insect_genus.id !== null) {
        this.insectGenus.forEach(genus => {
          if (genus.id === this.classification.insect_genus.id) {
            this.classification.insect_genus.gbif_id = genus.gbif_id
            this.classification.insect_genus.name = genus.name
            this.classification.insect_genus.family.id = genus.family.id
            this.genusQuery = genus.name

            this.classification.insect_family.id = genus.family.id

            this.classification.insect_species.genus.id = genus.id
          }
        })
      } else {
        this.emptyInsectFields('genus')
      }
      this.setInsectOrder()
    },
    setInsectGenus() {
      if (this.classification.insect_species.id !== null) {
        this.insectSpecies.forEach(species => {
          if (species.id === this.classification.insect_species.id) {
            this.classification.insect_species.gbif_id = species.gbif_id
            this.classification.insect_species.name = species.name
            this.classification.insect_species.genus.id = species.genus.id
            this.speciesQuery = species.name

            this.classification.insect_genus.id = species.genus.id
          }
        })
      }
      this.setInsectFamily()
    },
    maximizeProbability() {
      if (this.classification.probability > 1) {
        this.classification.probability = 1
      }
    }
  }
}
</script>

<style lang="scss">
.deselect-item {
  color: #007bff;
}

.selected-item {
  color: #007bff;
  font-weight: bold;

  &-after {
    color: darkgray;
    font-weight: normal;
    float: right;
  }
}
</style>
