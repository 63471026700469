<template>
  <b-card v-if="showComponent" footer-class="bg-white text-right">
    <b-card-title class="font-weight-light mb-4" :title="$t('dataset.classification-data')" />

    <ClassificationPageable
      v-model="classificationData"
      :dataset-id="datasetId"
      :disableAll="viewOnly"
      :page-size="perPage"
      :key="perPage"
    />

    <template #footer v-if="!viewOnly">
      <b-button :disabled="!isDataComplete" variant="primary" size="sm" @click="saveClassificationData">{{
        $t('general.save')
      }}</b-button>
    </template>
  </b-card>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import ClassificationPageable from '@/components/datasets/classifications/ClassificationPageable'
import { cloneDeep as _cloneDeep } from 'lodash'
import breakpoints from '@/util/BreakpointsUtil'

export default {
  name: 'ClassificationForm',
  components: { ClassificationPageable },
  mixins: [ApiMixin, NotificationMixin],
  props: {
    datasetId: {
      required: true,
      type: Number
    },
    viewOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      objectModels: {
        insect: {
          id: null,
          name: '',
          gbif_id: null
        }
      },
      classificationData: []
    }
  },
  watch: {
    datasetId: {
      handler() {
        this.getClassificationData()
      }
    }
  },
  mounted() {
    this.getClassificationData()
  },
  computed: {
    breakpoints,
    perPage() {
      if (!this.breakpoints.screen || !this.breakpoints.screen || !this.breakpoints.screen.lt) {
        return 3
      }
      if (this.breakpoints.screen.lt.sm) {
        return 1
      } else if (this.breakpoints.screen.lt.md) {
        return 2
      } else {
        return 3
      }
    },
    isDataComplete() {
      let complete = true
      this.classificationData.forEach(classification => {
        if (classification.insect_order.id === null) {
          complete = false
        }
      })
      return complete
    },
    showComponent() {
      return !this.viewOnly || (this.viewOnly && this.classificationData.length > 0)
    }
  },
  methods: {
    getClassificationData() {
      let self = this
      self.classificationData.splice(0)
      self.getRequest(
        `/datasets/${this.datasetId}/classifications`,
        new RequestConfig()
          .onSuccess(res => {
            self.classificationData = res.data
            this.prepareClassifications()
            this.orderClassifications()
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    prepareClassifications() {
      this.classificationData.forEach(classification => {
        if (classification.insect_family === null) {
          classification.insect_family = _cloneDeep(this.objectModels.insect)
          classification.insect_family.order = { id: null }
        }
        if (classification.insect_genus === null) {
          classification.insect_genus = _cloneDeep(this.objectModels.insect)
          classification.insect_genus.family = { id: null }
        }
        if (classification.insect_species === null) {
          classification.insect_species = _cloneDeep(this.objectModels.insect)
          classification.insect_species.genus = { id: null }
        }
      })
    },
    orderClassifications() {
      this.classificationData.sort(a => (a.type === 'HUMAN' || a.type === 'MACHINE' ? 1 : a.type !== 'HUMAN' ? -1 : 0))
    },
    async saveClassificationData() {
      let self = this
      for (const classification of self.classificationData) {
        if (classification.id === null) {
          await this.createNewClassification(classification)
        } else {
          await this.updateClassification(classification)
        }
      }
      self.getClassificationData()
      self.displaySuccess(this.$t('dataset.save-classification-successful'))
    },
    createNewClassification(classification) {
      let self = this
      let copied = Object.assign({}, classification)
      copied = this.setEmptyObjectsToNull(copied)
      let classificationJSON = JSON.stringify(copied)
      let datasetId = self.datasetId
      return self.postRequest(
        `/datasets/${datasetId}/classifications`,
        classificationJSON,
        new RequestConfig()
          .onSuccess(res => {
            console.debug('Classification successfully created: ', res.data)
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    updateClassification(classification) {
      let self = this
      // let classificationJSON = JSON.stringify(classification) <- why not needed?
      let datasetId = self.datasetId
      let classificationId = classification.id
      let copied = Object.assign({}, classification)
      copied = this.setEmptyObjectsToNull(copied)
      return self.putRequest(
        `/datasets/${datasetId}/classifications/${classificationId}`,
        copied,
        new RequestConfig()
          .onSuccess(res => {
            console.debug('Classification successfully updated: ', res.data)
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    setEmptyObjectsToNull(classification) {
      if (classification.insect_family.id === null) {
        classification.insect_family = null
      }
      if (classification.insect_genus.id === null) {
        classification.insect_genus = null
      }
      if (classification.insect_species.id === null) {
        classification.insect_species = null
      }
      return classification
    }
  }
}
</script>
