<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" class="mb-3" footer-class="text-right">
      <b-card-title class="text-center mb-5" :title="$t('dataset.header-edit', [datasetId])"></b-card-title>
      <b-row class="mb-3">
        <b-col>
          <b-button
            v-if="!isAnyQueryParamMissing"
            :disabled="isFirstPosition"
            @click="loadPreviousDataset"
            class="float-left"
            size="sm"
            variant="outline-secondary"
          >
            {{ $t('dataset.previous-dataset') }}
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-if="!isAnyQueryParamMissing"
            :disabled="isLastPosition"
            @click="loadNextDataset"
            class="float-right"
            variant="outline-secondary"
            size="sm"
          >
            {{ $t('dataset.next-dataset') }}
          </b-button>
        </b-col>
      </b-row>

      <div v-if="dataset !== null">
        <RawDataForm v-model="rawData" :dataset-id="datasetId" class="mb-4" />
        <InsectSizeForm v-model="insectSize" :dataset-id="datasetId" class="mb-4" :disable-all="viewOnly" />
        <ClassificationForm :dataset-id="datasetId" class="mb-4" :view-only="viewOnly" />
        <WingbeatForm
          v-if="dataset !== null && dataset.measurement_wingbeat !== null"
          :dataset-id="datasetId"
          class="mb-3"
        />
        <MediaForm
          :key="datasetId"
          v-if="dataset !== null && (hasVideo || hasImages)"
          :dataset-id="datasetId"
          :has-video="hasVideo"
          class="mb-4"
        />
        <TagForm :dataset-id="datasetId" class="mb-4" :view-only="viewOnly" />
      </div>
      <div v-else-if="loading && dataset === null" class="text-center">
        <LoadingSpinner />
      </div>
      <div v-else-if="!loading && dataset === null" class="text-center">
        <h2>{{ $t('dataset.404-notfound-title') }}</h2>
        <p>{{ $t('dataset.404-notfound-message') }}</p>
      </div>

      <template #footer>
        <b-row>
          <b-col>
            <b-button
              v-if="!isAnyQueryParamMissing"
              :disabled="isFirstPosition"
              @click="loadPreviousDataset"
              class="float-left"
              size="sm"
              variant="outline-secondary"
            >
              {{ $t('dataset.previous-dataset') }}
            </b-button>
          </b-col>
          <b-col style="display: flex" class="justify-content-center">
            <b-button variant="outline-secondary" size="sm" @click="cancelEditing"
              >{{ viewOnly ? $t('dataset.back-to-overview') : $t('dataset.cancel') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-if="!isAnyQueryParamMissing"
              :disabled="isLastPosition"
              @click="loadNextDataset"
              class="float-right"
              variant="outline-secondary"
              size="sm"
            >
              {{ $t('dataset.next-dataset') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-card>

    <DeleteForm
      v-if="!viewOnly && dataset !== null"
      :dataset-id="datasetId"
      class="mt-5"
      :redirect-func="
        !isAnyQueryParamMissing
          ? !isLastPosition
            ? loadNextDatasetAfterDelete
            : !isFirstPosition
            ? loadPreviousDataset
            : undefined
          : undefined
      "
    />
  </b-container>
</template>

<script>
import RawDataForm from '@/components/datasets/RawDataForm'
import InsectSizeForm from '@/components/datasets/InsectSizeForm'
import ClassificationForm from '@/components/datasets/classifications/ClassificationForm'
import TagForm from '@/components/datasets/TagForm'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import WingbeatForm from '@/components/datasets/WingbeatForm'
import DeleteForm from '@/components/datasets/DeleteForm'
import MediaForm from '@/components/datasets/MediaForm'
import LoadingSpinner from '@/components/LoadingSpinner'
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash'

export default {
  name: 'DatasetEdit',
  mixins: [ApiMixin, NotificationMixin],
  components: {
    MediaForm,
    DeleteForm,
    WingbeatForm,
    TagForm,
    ClassificationForm,
    InsectSizeForm,
    RawDataForm,
    LoadingSpinner
  },
  data() {
    return {
      loading: true,
      datasetId: parseInt(this.$route.params.datasetId),
      dataset: null,
      nextDatasetId: null,
      previousDatasetId: null,
      sensorLocation: null,
      rawData: {},
      insectSize: {},
      datasetTags: []
    }
  },
  mounted() {
    if (this.isAnyQueryParamMissing) {
      let datasetId = this.$route.params.datasetId
      this.loadDatasetPerId(datasetId, null)
    } else {
      this.loadFullDatasetPerIdAndIdsOfPreviousAndNext()
    }
  },
  watch: {
    '$route.query': {
      handler: function () {
        this.loadFullDatasetPerIdAndIdsOfPreviousAndNext()
      }
    }
  },
  computed: {
    currentPosition() {
      return this.$route.query.position ? parseInt(this.$route.query.position) : 0
    },
    isFirstPosition() {
      return this.previousDatasetId === undefined || this.previousDatasetId === null
    },
    isLastPosition() {
      return this.nextDatasetId === undefined || this.nextDatasetId === null
    },
    hasVideo() {
      return this.dataset.video_s3_object === null ? false : true
    },
    hasImages() {
      return this.dataset.dataset_images !== null && this.dataset.dataset_images.length > 0
    },
    viewOnly() {
      return this.$route.params.viewOnly === undefined
        ? this.$route.params.mode === 'view'
        : this.$route.params.viewOnly
    },
    isAnyQueryParamMissing() {
      let noParameters = _isEmpty(this.$route.query)
      let noPageParam = !this.$route.query.page && this.$route.query.page !== 0
      let noSizeParam = !this.$route.query.size
      let noSortParam = !this.$route.query.sort
      let noPosition = !this.$route.query.position && this.$route.query.position !== 0
      return noParameters || noPageParam || noSizeParam || noSortParam || noPosition
    }
  },
  methods: {
    routeQueryToUrl(queryObject) {
      let url = ''
      Object.entries(queryObject).forEach(entry => {
        url += '&' + encodeURIComponent(entry[0]) + '=' + encodeURIComponent(entry[1])
      })
      if (url.length > 0) {
        url = url.substring(1)
      }
      return url
    },
    loadPreviousDatasetId(position) {
      let self = this
      if (position - 1 < 0) {
        self.previousDatasetId = null
      } else {
        self.loadDatasetIdOnly(position - 1).then(id => {
          self.previousDatasetId = id
        })
      }
    },
    loadNextDatasetId(position) {
      let self = this
      self.loadDatasetIdOnly(position + 1).then(id => {
        self.nextDatasetId = id
      })
    },
    loadNextDataset() {
      const currentPosition = parseInt(this.$route.query.position)
      this.$router.push({
        params: { ...this.$route.params, datasetId: this.nextDatasetId },
        query: { ...this.$route.query, position: currentPosition + 1 }
      })
    },
    loadNextDatasetAfterDelete() {
      const currentPosition = parseInt(this.$route.query.position)
      this.$router.push({
        params: { ...this.$route.params, datasetId: this.nextDatasetId },
        query: { ...this.$route.query, position: currentPosition }
      })
    },
    loadPreviousDataset() {
      const currentPosition = parseInt(this.$route.query.position)
      this.$router.push({
        params: { ...this.$route.params, datasetId: this.previousDatasetId },
        query: { ...this.$route.query, position: currentPosition - 1 }
      })
    },
    loadDatasetPerId(datasetId) {
      let self = this
      return this.getRequest(
        `/datasets/${datasetId}`,
        new RequestConfig()
          .onSuccess(res => {
            self.loading = false
            self.dataset = res.data
            self.datasetId = self.dataset.id
            self.setInsectSize(self.dataset)
            self.setRawData(self.dataset)
            let currentPage = self.computeCurrentPage()
            if (!self.isAnyQueryParamMissing && currentPage !== parseInt(self.$route.query.page)) {
              self.$router.replace({
                params: { ...self.$route.params, datasetId: datasetId },
                query: { ...self.$route.query, page: currentPage }
              })
            }
            return res.data
          })
          .onError(error => {
            self.loading = false
            console.log(error)
          })
      )
    },
    loadDatasetIdOnly(position) {
      let self = this
      let queryCopy = _cloneDeep(self.$route.query)
      queryCopy.page = position.toString()
      queryCopy.size = '1'

      let querySuffix = self.routeQueryToUrl(queryCopy)
      let url = '/datasets/_views/id_only/paginated?' + querySuffix
      return self.getRequest(
        url,
        new RequestConfig()
          .onSuccess(res => {
            self.loading = false
            if (res.data.ids.length > 0) {
              return res.data.ids[0]
            }
            return null
          })
          .onError(error => {
            self.loading = false
            console.log(error)
            return null
          })
      )
    },
    loadFullDatasetPerIdAndIdsOfPreviousAndNext() {
      let self = this
      let datasetId = self.$route.params.datasetId
      let currentPosition = parseInt(self.$route.query.position)
      self.loadDatasetPerId(datasetId, currentPosition).then(() => {
        self.loadNextDatasetId(currentPosition)
        self.loadPreviousDatasetId(currentPosition)
        window.scrollTo(0, 0)
      })
    },
    setInsectSize(datasetDto) {
      this.insectSize = {
        width: datasetDto.measurement_size_insect_width,
        length: datasetDto.measurement_size_insect_length
      }
    },
    setRawData(datasetDto) {
      this.rawData = {
        sensorId: datasetDto.sensor.id,
        user: datasetDto.user.fullName,
        userId: datasetDto.user.id,
        timeStamp: datasetDto.measurement_datetime,
        location: datasetDto.sensor.sensor_location.city,
        latitude: datasetDto.sensor.sensor_location.latitude,
        longitude: datasetDto.sensor.sensor_location.longitude,
        temperature: datasetDto.measurement_temperature,
        humidity: datasetDto.measurement_humidity,
        airPressure: datasetDto.measurement_air_pressure,
        luminosity: datasetDto.measurement_luminosity,
        windSensorSpeed: datasetDto.measurement_wind_sensor_speed,
        windSensorDirection: datasetDto.measurement_wind_sensor_direction,
        rainfall: datasetDto.measurement_rainfall
      }
    },
    cancelEditing() {
      // TODO add warning modal "You are loosing unsaved changes"
      let page = this.computeCurrentPage()
      let query = Object.assign({}, this.$route.query)
      delete query.position
      this.$router.replace({
        query: { ...query, page: page }
      })
      this.$router.push({ name: 'datasets', query: this.$route.query })
    },
    computeCurrentPage() {
      if (this.isAnyQueryParamMissing) {
        return 0
      }
      let position = this.currentPosition
      let size = this.$route.query.size
      return Math.floor(position / size)
    }
  }
}
</script>
