<template>
  <b-card>
    <b-card-title class="font-weight-light mb-4" :title="$t('dataset.raw-data')" />
    <b-row class="justify-content-end">
      <b-col sm="12" md="8" xl="5">
        <b-form-group :label="$t('dataset.time-stamp')" label-for="time-stamp" label-cols-sm="5" label-align-sm="left">
          <b-form-input disabled id="time-stamp" v-model="measurementDatetimeFormatted" />
        </b-form-group>
        <b-form-group
          v-if="isAdmin || !!rawDataObject.user"
          :label="$t('dataset.user-and-user-id')"
          label-for="user-data"
          label-cols-sm="5"
          label-align-sm="left"
        >
          <b-input-group id="user-data">
            <b-form-input disabled class="w-50" v-model="rawDataObject.user" />
            <b-form-input disabled class="ml-2" v-model="rawDataObject.userId" />
          </b-input-group>
        </b-form-group>
        <b-form-group
          v-else
          :label="$t('dataset.user-id')"
          label-for="user-data"
          label-cols-sm="5"
          label-align-sm="left"
        >
          <b-input-group id="user-data">
            <b-form-input disabled v-model="rawDataObject.userId" />
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="$t('dataset.sensor-id-and-location')"
          label-for="sensor-id"
          label-cols-sm="5"
          label-align-sm="left"
        >
          <b-input-group id="user-data">
            <b-form-input disabled class="mr-2" id="sensor-id" v-model="rawDataObject.sensorId" />
            <b-form-input disabled class="w-50" id="location" v-model="rawDataObject.location" />
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="$t('dataset.geo-coordinates')"
          label-for="geo-coordinates"
          label-cols-sm="5"
          label-align-sm="left"
        >
          <b-input-group id="geo-coordinates">
            <b-form-input type="number" disabled v-model="rawDataObject.latitude" />
            <b-form-input type="number" disabled class="ml-2" v-model="rawDataObject.longitude" />

            <b-input-group-append>
              <b-button
                size="sm"
                :title="$t('dataset.openstreetmap-link')"
                variant="secondary"
                class="pt-2"
                :href="`https://www.openstreetmap.org/query?mlat=${rawDataObject.latitude}&mlon=${rawDataObject.longitude}#map=16/${rawDataObject.latitude}/${rawDataObject.longitude}`"
              >
                <b-icon icon="globe2" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="4" class="d-none d-md-flex d-xl-none" />

      <b-col sm="6" md="5" xl="3">
        <b-form-group
          :label="$t('dataset.temperature')"
          label-for="temperature"
          label-cols-sm="6"
          label-align-sm="left"
        >
          <b-form-input type="number" disabled id="temperature" v-model="rawDataObject.temperature" />
        </b-form-group>
        <b-form-group
          :label="$t('dataset.air-pressure')"
          label-for="air-pressure"
          label-cols-sm="6"
          label-align-sm="left"
        >
          <b-form-input type="number" disabled id="air-pressure" v-model="rawDataObject.airPressure" />
        </b-form-group>
        <b-form-group :label="$t('dataset.humidity')" label-for="humidity" label-cols-sm="6" label-align-sm="left">
          <b-form-input type="number" disabled id="humidity" v-model="rawDataObject.humidity" />
        </b-form-group>
        <b-form-group :label="$t('dataset.rainfall')" label-for="rainfall" label-cols-sm="6" label-align-sm="left">
          <b-form-input type="number" disabled id="rainfall" v-model="rawDataObject.rainfall" />
        </b-form-group>
      </b-col>

      <b-col md="1" class="d-none d-md-flex d-xl-none" />

      <b-col sm="6" md="5" xl="3">
        <b-form-group
          :label="$t('dataset.wind-sensor-speed')"
          label-for="wind-sensor-speed"
          label-cols-sm="6"
          label-align-sm="left"
        >
          <b-form-input type="number" disabled id="wind-sensor-speed" v-model="rawDataObject.windSensorSpeed" />
        </b-form-group>
        <b-form-group
          :label="$t('dataset.wind-sensor-direction')"
          label-for="wind-sensor-direction"
          label-cols-sm="6"
          label-align-sm="left"
        >
          <b-form-input disabled id="wind-sensor-direction" v-model="rawDataObject.windSensorDirection" />
        </b-form-group>
        <b-form-group :label="$t('dataset.luminosity')" label-for="luminosity" label-cols-sm="6" label-align-sm="left">
          <b-form-input type="number" disabled id="luminosity" v-model="rawDataObject.luminosity" />
        </b-form-group>
      </b-col>

      <b-col md="1" xl="1" class="d-none d-md-flex" />
    </b-row>
  </b-card>
</template>

<script>
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import { ApiMixin } from '@/mixins/ApiMixin'

export default {
  name: 'RawDataForm',
  mixins: [ApiMixin],
  props: {
    value: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    datasetId: {
      required: true,
      type: Number
    }
  },
  computed: {
    rawDataObject: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    measurementDatetimeFormatted() {
      return LocalDateTimeFormatter.formatLocaleDateTime(this.rawDataObject.timeStamp)
    }
  }
}
</script>
