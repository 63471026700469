<template>
  <b-card footer-class="bg-white text-right">
    <b-card-title class="font-weight-light mb-4" :title="$t('dataset.insect-size')" />

    <b-row>
      <b-col cols="8" md="5" xl="3">
        <b-form-group
          :label="$t('dataset.insect-size-length')"
          label-for="insect-length"
          label-cols-sm="5"
          label-align-sm="left"
        >
          <b-form-input
            :disabled="disableAll"
            id="insect-length"
            type="number"
            step=".1"
            min="0"
            max="99.9"
            v-model.number="insectSize.length"
          />
        </b-form-group>
        <b-form-group
          :label="$t('dataset.insect-size-width')"
          label-for="insect-width"
          label-cols-sm="5"
          label-align-sm="left"
        >
          <b-form-input
            :disabled="disableAll"
            id="insect-width"
            type="number"
            step=".1"
            min="0"
            max="99.9"
            v-model.number="insectSize.width"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <template #footer v-if="!disableAll">
      <b-button variant="primary" size="sm" @click="saveSizeData">{{ $t('general.save') }}</b-button>
    </template>
  </b-card>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'

export default {
  name: 'InsectSizeForm',
  mixins: [ApiMixin, NotificationMixin],
  props: {
    value: {
      required: true,
      type: Object,
      default: () => {
        return {}
      }
    },
    datasetId: {
      required: true,
      type: Number
    },
    disableAll: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    insectSize: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    saveSizeData() {
      let self = this
      let insectSizeJSON = this.insectSize
      let datasetId = self.datasetId
      return self.putRequest(
        `/datasets/${datasetId}/size_insect`,
        insectSizeJSON,
        new RequestConfig()
          .onSuccess(res => {
            console.debug('Insect Size successfully updated: ', res.data)
            self.displaySuccess(this.$t('dataset.save-insect-size-successful'))
          })
          .onError(error => {
            console.log(error)
          })
      )
    }
  }
}
</script>
