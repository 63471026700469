<template>
  <b-card
    v-if="showComponent"
    footer-class="bg-white text-right"
    :style="viewOnly ? 'min-height: 120px' : 'min-height: 200px;'"
  >
    <b-card-title class="font-weight-light mb-4" :title="$t('dataset.tags')" />

    <div v-if="viewOnly">
      <h5>
        <DatasetTagBadge v-for="(tag, index) in datasetTags" :key="index" :tag="tag.value" :allow-removal="false" />
      </h5>
    </div>

    <DatasetTagMultiselect
      v-else
      id="datasetTagMultiselect"
      v-model="datasetTags"
      :include-auto-tags="false"
      :allow-auto-tag-removal="false"
      :allow-tag-creation="true"
      :disableAll="viewOnly"
    />

    <template #footer v-if="!viewOnly">
      <b-button variant="primary" size="sm" @click="saveDataSetTags">{{ $t('general.save') }}</b-button>
    </template>
  </b-card>
</template>

<script>
import DatasetTagMultiselect from '@/components/DatasetTagMultiselect'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import DatasetTagBadge from '@/components/DatasetTagBadge.vue'

export default {
  name: 'TagForm',
  mixins: [ApiMixin, NotificationMixin],
  components: { DatasetTagBadge, DatasetTagMultiselect },
  props: {
    datasetId: {
      required: true,
      type: Number
    },
    viewOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      datasetTags: [],
      originalTagList: []
    }
  },
  watch: {
    datasetId: {
      handler() {
        this.getDatasetTags()
      }
    }
  },
  mounted() {
    this.getDatasetTags()
  },
  methods: {
    getDatasetTags() {
      let self = this
      let datasetId = self.datasetId
      self.datasetTags.splice(0)
      self.originalTagList.splice(0)
      self.getRequest(
        `/datasets/${datasetId}/dataset_tags`,
        new RequestConfig()
          .onSuccess(res => {
            res.data.forEach(datasetTag => {
              self.datasetTags.push({
                value: datasetTag,
                text: datasetTag.name
              })
              self.originalTagList.push({
                value: datasetTag,
                text: datasetTag.name
              })
            })
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    saveDataSetTags() {
      let self = this
      let operations = []
      this.datasetTags.forEach(tag => {
        if (tag.value.id === null) {
          operations.push(this.createNewDatasetTag(tag))
        } else if (!tag.value.auto_tag) {
          let isLinked = false
          this.originalTagList.forEach(original => {
            if (original.value.id === tag.value.id) {
              isLinked = true
            }
          })
          if (!isLinked) {
            operations.push(this.linkDatasetTag(tag))
          }
        }
      })
      this.tagsToDelete.forEach(tag => {
        operations.push(this.deleteDatasetTag(tag))
      })
      Promise.allSettled(operations).then(results => {
        const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason)

        if (rejected.length > 0) {
          console.log(rejected)
          self.displayError(this.$t('dataset.save-dataset-tag-error'))
          self.getDatasetTags()
        } else {
          self.getDatasetTags()
          self.displaySuccess(this.$t('dataset.save-dataset-tag-successful'))
        }
      })
    },
    createNewDatasetTag(tag) {
      let self = this
      let datasetId = this.datasetId
      let tagJSON = JSON.stringify(tag.value)
      return self.postRequest(
        `/datasets/${datasetId}/dataset_tags`,
        tagJSON,
        new RequestConfig()
          .onSuccess(res => {
            console.debug('DatasetTag successfully created: ', res.data)
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    deleteDatasetTag(tag) {
      let self = this
      let datasetId = this.datasetId
      let datasetTagId = tag.id
      return self.deleteRequest(
        `/datasets/${datasetId}/dataset_tags/${datasetTagId}`,
        new RequestConfig()
          .onSuccess(res => {
            console.debug('DatasetTag successfully deleted: ', res.data)
          })
          .onError(error => {
            console.log(error)
          })
      )
    },
    linkDatasetTag(tag) {
      let self = this
      let datasetId = this.datasetId
      let tagJSON = JSON.stringify(tag.value)
      let datasetTagId = tag.value.id
      return self.putRequest(
        `/datasets/${datasetId}/dataset_tags/${datasetTagId}`,
        tagJSON,
        new RequestConfig()
          .onSuccess(res => {
            console.debug('DatasetTag successfully linked: ', res.data)
          })
          .onError(error => {
            console.log(error)
          })
      )
    }
  },
  computed: {
    showComponent() {
      return !this.viewOnly || (this.viewOnly && this.datasetTags.length > 0)
    },
    tagsToDelete() {
      let tagsToDelete = []
      this.originalTagList.forEach(originalTag => {
        let toBeDeleted = true
        this.datasetTags.forEach(tag => {
          if (tag.value.id === originalTag.value.id) {
            toBeDeleted = false
          }
        })
        if (toBeDeleted) {
          tagsToDelete.push(originalTag.value)
        }
      })
      return tagsToDelete
    }
  }
}
</script>
