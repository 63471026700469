<template>
  <b-card>
    <b-card-title class="font-weight-light mb-4" :title="$t('dataset.wingbeat')" />

    <b-row class="mb-3 align-items-center">
      <b-col cols="12">
        <b-form-group
          :label="$t('dataset.wingbeat-signal')"
          label-for="wingbeat-audio"
          label-cols-sm="4"
          label-cols-md="3"
          label-cols-xl="2"
          label-align-sm="left"
          content-cols="6"
          content-cols-md="9"
        >
          <audio v-if="wingbeatData.wav_s3_object" controls id="wingbeat-audio">
            <source :src="wavUrl" :type="wingbeatData.wav_s3_object.media_type" />
          </audio>
        </b-form-group>
        <b-form-group
          :label="$t('dataset.wingbeat-samplingrate')"
          label-for="wingbeat-samplingrate"
          label-cols-sm="4"
          label-cols-md="3"
          label-cols-xl="2"
          label-align-sm="left"
          content-cols="4"
          content-cols-md="2"
        >
          <b-form-input v-model="wingbeatData.sample_rate" disabled id="wingbeat-samplingrate" />
        </b-form-group>
        <b-form-group
          :label="$t('dataset.wingbeat-psd-graphic')"
          label-for="wingbeat-psd"
          label-cols-sm="4"
          label-cols-md="3"
          label-cols-xl="2"
          label-align-sm="left"
        >
          <b-img class="wingbeat_png" :src="imgUrl" id="wingbeat-psd" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'

export default {
  name: 'WingbeatForm',
  mixins: [ApiMixin],
  props: {
    datasetId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      apiBaseUrl: this.$store.getters.getConfig.API_BASE_URL,
      wingbeatData: {}
    }
  },
  watch: {
    datasetId: {
      handler() {
        this.loadWingbeatData()
      }
    }
  },
  mounted() {
    this.loadWingbeatData()
  },
  computed: {
    wingbeatUrl() {
      return `${this.apiBaseUrl}/datasets/${this.datasetId}/measurement_wingbeat`
    },
    imgUrl() {
      return `${this.wingbeatUrl}/image_s3_object/download`
    },
    wavUrl() {
      return `${this.wingbeatUrl}/wav_s3_object/download`
    }
  },
  methods: {
    loadWingbeatData() {
      let self = this
      this.getRequest(
        `/datasets/${this.datasetId}/measurement_wingbeat`,
        new RequestConfig()
          .onSuccess(res => {
            self.wingbeatData = res.data
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    }
  }
}
</script>

<style>
img.wingbeat_png {
  border: 1px solid dimgray;
  max-width: 100%;
}
</style>
